<template>
  <div>
    <div class="flex justify-between items-center">
      <VTitle :title="$t('app.details')" class="section-title" />

      <LangDropdown
        v-if="currentTab === 'details'"
        :lang="lang"
        :texts="attestation.texts"
        :endpoint="`${endpoint}.texts.delete`"
        attribute="attestation_id"
        @update="onUpdateLang"
        @cleared="$emit('refresh')"
      />
    </div>

    <Form
      :id="id"
      :is-loading="isLoading"
      :data="data"
      @update:tab="updateTab"
      @click:cancel="$emit('click:cancel')"
      @click:save="$emit('click:save', $event)"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useAttestation from "@/composables/useAttestation";
import useLangDropdown from "@/composables/useLangDropdown";
import useTexts from "@/composables/useTexts";
import useTabs from "@/composables/useTabs";
// Components
import VTitle from "@/components/VTitle";
import LangDropdown from "@/components/LangDropdown";
import Form from "./Form";

export default {
  components: {
    VTitle,
    Form,
    LangDropdown
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    attestation: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click:cancel", "click:save", "refresh", "remove:text"],
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Computed
    const data = computed(() => props.attestation);
    const texts = computed(() => data.value.texts);
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.details")} - ${t("app.attestations", 2)} - ${t("app.teaching")}`;

      const name = getText(props.attestation?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Composables
    const { updateTab, currentTab } = useTabs("details");
    const { endpoint, localizedFields } = useAttestation();
    const { getText } = useTexts();
    const { lang, updateLang } = useLangDropdown({
      texts,
      localizedFields
    });

    return {
      documentTitle,
      data,
      // useTabs
      currentTab,
      updateTab,
      // useAttestation
      endpoint,
      // useLangDropdown
      lang,
      onUpdateLang: updateLang
    };
  }
};
</script>
