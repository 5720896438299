<template>
  <FormCard
    :is-loading="isLoading"
    @click:cancel="onCancel"
    @click:save="onSaveForm"
  >
    <template #tabs>
      <VTabs :current-tab="currentTab" :tabs="tabs" @click="onClickUpdateTab" />
    </template>

    <template #content>
      <Details
        v-if="isCurrentTab('details')"
        ref="details"
        :data="data"
        @click:save="$emit('click:save', $event)"
      />

      <Competencies
        v-if="isCurrentTab('competencies')"
        :id="id"
        ref="competencies"
        :endpoint="endpoint"
        :competencies="data.competencies"
        @add:competency="$emit('refresh')"
        @remove:competency="$emit('refresh')"
      />
    </template>
  </FormCard>
</template>

<script>
import { computed, ref } from "vue";
// Composables
import useForm from "@/composables/useForm";
import useTabs from "@/composables/useTabs";
import useAttestation from "@/composables/useAttestation";
// Mixins
import FormMixin from "@/mixins/FormMixin.js";
// Components
import VTabs from "@/components/VTabs";
import FormCard from "@/components/FormCard";
import Details from "./forms/Details";
import Competencies from "@/components/templates/Competencies";

export default {
  components: {
    VTabs,
    Details,
    Competencies,
    FormCard
  },
  mixins: [FormMixin],
  props: {
    id: {
      type: [String, Number],
      default: ""
    }
  },
  emits: ["update:tab", "click:save", "refresh"],
  setup(props, context) {
    // Composables
    // eslint-disable-next-line
    const { buildTab, currentTab, updateTab, isCurrentTab } = useTabs("details");
    const { onCancel, isCreate } = useForm(props, context);
    const { endpoint } = useAttestation();

    // Data
    const details = ref(null);
    const competencies = ref(null);

    // Computed
    const tabs = computed(() => [
      buildTab({
        name: "details",
        hideIcon: true
      }),
      buildTab({
        name: "competencies",
        hideIcon: true,
        disabled: isCreate.value
      })
    ]);

    // Methods
    const onSaveForm = async () => {
      switch (currentTab.value) {
        case "details":
          await details.value.onSave();
          break;
        case "competencies":
          await competencies.value.onSave();
          break;
        default:
      }
    };

    const onClickUpdateTab = tab => {
      updateTab(tab);
      context.emit("update:tab", currentTab.value);
    };

    return {
      tabs,
      details,
      competencies,
      onSaveForm,
      onClickUpdateTab,
      // useAttestation
      endpoint,
      // useForm
      onCancel,
      // useTabs
      currentTab,
      isCurrentTab
    };
  }
};
</script>
