<template>
  <div class="grid grid-cols-2 gap-x-8 gap-y-6">
    <LocalizedInput
      component="VText"
      :model-value="value.name"
      :errors="v.name"
      :label="$t('app.name')"
      :lang="lang"
      :readonly="readonly || locked"
      field="name"
      class="col-span-2"
      @update:modelValue="onUpdate"
      @keyup.enter="onSave"
    />

    <LocalizedInput
      component="VWysiwyg"
      :model-value="value.details"
      :errors="v.details"
      :label="$t('app.details')"
      :lang="lang"
      class="col-span-2"
      field="details"
      :readonly="readonly || locked"
      @update:modelValue="onUpdate"
    />

    <VSelect
      v-model="v.default_approve_by.$model"
      :label="$t('app.default_approve_by')"
      :errors="v.default_approve_by.$errors"
      :options="options.default_approve_by"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    >
      <template #hint>
        {{ $t("hints.teaching_attestations_default_approve_by") }}
      </template>
    </VSelect>

    <VSwitch
      v-model="v.status.$model"
      :label="$t('app.status')"
      true-value="Active"
      false-value="Inactive"
      :readonly="readonly || locked"
      :errors="v.status.$errors"
    />

    <VSelect
      v-model="v.default_assign_competencies_upon.$model"
      :label="$t('app.default_assign_competencies_upon')"
      :errors="v.default_assign_competencies_upon.$errors"
      :options="options.default_assign_competencies_upon"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    >
      <template #hint>
        {{ $t("hints.teaching_attestations_default_assign_competencies") }}
      </template>
    </VSelect>

    <VSwitch
      v-model="v.sys_admin_locked.$model"
      :label="$t('app.sys_admin_locked')"
      true-value="Yes"
      false-value="No"
      :readonly="readonly || (!isSysAdmin && locked)"
      :errors="v.sys_admin_locked.$errors"
    />

    <VSelect
      v-model="v.default_submit_by.$model"
      :label="$t('app.default_submit_by')"
      :errors="v.default_submit_by.$errors"
      :options="options.default_submit_by"
      :readonly="readonly || locked"
      @update:modelValue="onDefaultSubmitByChange"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.default_maximum_failed_attempts.$model"
      :label="$t('app.default_maximum_failed_attempts')"
      type="number"
      :readonly="readonly || locked"
      :errors="v.default_maximum_failed_attempts.$errors"
      @keyup.enter="onSave"
    />

    <VTags
      v-model="v.tags.$model"
      :label="$t('app.tags')"
      class="col-span-2"
      :readonly="readonly || locked"
      :errors="v.tags.$errors"
    />
  </div>
</template>

<script>
import { inject } from "vue";
// Composables
import useOptions from "@/composables/useOptions";
import useAttestation from "@/composables/useAttestation";
import useForm from "@/composables/useForm";
// Mixins
import FormMixin from "@/mixins/FormMixin.js";

export default {
  mixins: [FormMixin],
  emits: ["click:save"],
  setup(props, context) {
    // Misc
    const lang = inject("lang");

    // Composables
    const { ALL_OPTIONS } = useOptions();
    const { value, rules, options, localizedFields } = useAttestation();
    const { v, onUpdate, onSave, isSysAdmin, locked } = useForm(
      props,
      context,
      {
        value,
        rules,
        localizedFields,
        lang
      }
    );

    // Methods
    /**
     * Business logic
     * If default_submit_by is not a learner, default_approve_by should be none
     * @params {String} newValue
     */
    const onDefaultSubmitByChange = newValue => {
      if (newValue !== ALL_OPTIONS.LEARNER.value) {
        value.value.default_approve_by = ALL_OPTIONS.NONE.value;
      }
    };

    return {
      value,
      options,
      lang,
      onDefaultSubmitByChange,
      // useForm
      onSave,
      isSysAdmin,
      locked,
      v,
      onUpdate
    };
  }
};
</script>
